<template>
  <v-container
    grid-list-xl>
    <v-layout
      fluid
      pt-0
      wrap>
      <v-flex xs12>
        <v-form
          ref="invoiceForm"
          v-model="valid"
          lazy-validation>
          <app-card
            :footer="true"
            heading="Invoice information Editable"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.number"
                      :rules="[v => Boolean(v) || 'Please provide a number']"
                      type="text"
                      label="Invoice number"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.poNumber"
                      type="text"
                      label="PO number"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.driverName"
                      :rules="[v => Boolean(v) || 'Please provide a driver name']"
                      type="text"
                      label="Driver name"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.passengerName"
                      :rules="[v => Boolean(v) || 'Please provide a customer name']"
                      type="text"
                      label="Customer name"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carBrand"
                      type="text"
                      label="Car brand"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carLicensePlate"
                      type="text"
                      label="Car license plate"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carModel"
                      type="text"
                      label="Car model"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carTransmissionType"
                      type="text"
                      label="Car transmission type"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.invoicedAt"
                      type="datetime-local"
                      label="Invoiced At"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <v-btn
              :loading="saveLoading"
              color="primary"
              @click="saveFunctionClick">
              Save
            </v-btn>
          </app-card>
        </v-form>
        <app-card
          :footer="true"
          heading="Invoice information Readonly"
          col-classes="xl12 lg12 md12 sm12 xs12"
          custom-classes="mb-0"
        >
          <div>
            <rotate-square2 v-if="loading" />
            <v-container
              v-else
              grid-list-xl>
              <v-layout
                row
                wrap>
                <v-flex
                  xs12>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.startLocation.address"
                    readonly
                    type="text"
                    label="Start location"
                  />
                </v-flex>
                <v-flex
                  xs12>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.endLocation.address"
                    readonly
                    type="text"
                    label="End location"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.declaredDuration.time"
                    readonly
                    type="time"
                    label="Declared duration"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.declaredWaitingTime.time"
                    readonly
                    type="time"
                    label="Declared waiting time"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.ride.id"
                    readonly
                    type="text"
                    label="Ride id"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.rideCost"
                    readonly
                    type="text"
                    label="Ride cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.waitingTimeCost"
                    readonly
                    type="text"
                    label="Waiting time cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.publicTransportCost"
                    readonly
                    type="text"
                    label="Public transport cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.platformCost"
                    readonly
                    type="text"
                    label="Platform cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.insuranceCost"
                    readonly
                    type="text"
                    label="Insurance cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.extraCost"
                    readonly
                    type="text"
                    label="Extra cost"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.vat"
                    readonly
                    type="text"
                    label="VAT"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.totalExclVAT"
                    readonly
                    type="text"
                    label="Total exclude vat"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.totalInclVAT"
                    readonly
                    type="text"
                    label="Total include vat"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.sentAt"
                    readonly
                    type="datetime-local"
                    label="Sent at"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.exactSyncStatus"
                    readonly
                    type="text"
                    label="Sync status"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.exactSyncedAt"
                    readonly
                    type="datetime-local"
                    label="Last synced at"
                  />
                </v-flex>
                <v-flex
                  sm6>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="invoice.exactSyncError"
                    readonly
                    type="text"
                    label="Last sync error"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </app-card>
        <send-invoice-button
          color="secondary"
          button-classes='text-white'
          invoice-type='combined'
          :invoice-id="invoice.id"
          :after-action="requestData" />
      </v-flex>
    </v-layout>
    <confirmation-dialog
      ref="confirmationDialog"
      confirm="Save"
      cancel="Cancel"
      message="The invoice has already been sent or synced, are you sure you want to change it?"
      @onConfirm="saveFunction"
    />
  </v-container>
</template>

<script>
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import Request from '../../../../helpers/request'
import SendInvoiceButton from 'Components/Buttons/SendInvoiceButton.vue'
import moment from 'moment'

export default {
  components: { CrudTable, SendInvoiceButton },
  mixins: [Request],
  data () {
    return {
      valid: true,
      loading: true,
      saveLoading: false,
      data: [],
      invoice: {},
      TEXT_FIELD_MAX_LENGTH
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/invoices/${this.$route.params.id}`, {}, ({ data }) => {
        this.invoice = {
          ...data,
          startLocation: data.startLocation || {},
          endLocation: data.endLocation || {},
          declaredDuration: data.declaredDuration || {},
          declaredWaitingTime: data.declaredWaitingTime || {}
        }
      }, (loader) => { this.loading = loader })
    },
    saveFunctionClick () {
      if (this.$refs.invoiceForm.validate()) {
        if (this.invoice.sentAt || this.invoice.exactSyncStatus === 'synced') {
          this.$refs.confirmationDialog.openDialog()
        } else {
          this.saveFunction()
        }
      }
    },
    saveFunction () {
      this.$refs.confirmationDialog.close()

      if (this.$refs.invoiceForm.validate()) {
        this.saveLoading = true
        let body = {
          data: {
            passengerName: this.invoice.passengerName,
            address: this.invoice.address,
            poNumber: this.invoice.poNumber,
            driverName: this.invoice.driverName,
            carBrand: this.invoice.carBrand,
            carModel: this.invoice.carModel,
            carLicensePlate: this.invoice.carLicensePlate,
            carTransmissionType: this.invoice.carTransmissionType,
            declaredDuration: moment.duration(this.invoice.declaredDuration.time).asMinutes(),
            declaredWaitingTime: moment.duration(this.invoice.declaredWaitingTime.time).asMinutes(),
            number: this.invoice.number,
            invoicedAt: this.invoice.invoicedAt,
            expiredAt: this.invoice.expiredAt
          }
        }

        this.request('PATCH', `/invoices/${this.invoice.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoice has been changed'
          })
          this.saveLoading = false

          this.$router.push('/tables/invoices')
        }).catch(err => { this.saveLoading = false })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
  img {
    width: 124px;
    height: 124px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
  }
}
</style>
